<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on }">
        <v-btn dark v-on="on" color="grey darken-1" outlined raised
          >Return Serialized Item</v-btn
        >
      </template>
      <v-card>
        <ModelTitle title="Return Item" @close="close()" />

        <v-card-text class="mt-5">
          <v-row class="mt-5" justify="space-between">
            <v-col>
              <v-text-field
                @focus="$event.target.select()"
                label="Serial Number"
                hide-details
                outlined
                prepend-inner-icon="search"
                clearable
                dense
                rounded
                @keyup.enter="loadImeiNumber()"
                v-model="returnImeiNumber"
                ref="returnImeiNumber"
              ></v-text-field>
            </v-col>
            <v-col class="shrink">
              <v-btn color="success" @click="loadImeiNumber()">Search</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-if="isLoading">
          <div>
            <span class="font-weight-medium">Vendor Name: </span>
            <span>{{ imeiReturnResult.Purchase.Vendor.name }}</span>
          </div>
          <div>
            <span class="font-weight-medium">Invoice Date: </span>
            <span
              >{{ imeiReturnResult.Purchase.date | moment("MM/DD/YYYY") }} |
              {{ imeiReturnResult.Purchase.date | moment("from", "now") }}</span
            >
          </div>
          <div>
            <span class="font-weight-medium">Item Name: </span>
            <span>{{ imeiReturnResult.Product.name }}</span>
          </div>
          <div>
            <span class="font-weight-medium">Amount: </span>
            <span>{{ imeiReturnResult.cost | currency }}</span>
          </div>
          <v-card v-if="itemInStock" class="mt-2" dark color="red">
            <v-card-text
              class="text-center font-weight-bold title"
              style="color: white"
            >
              This item is not in stock.
            </v-card-text>
          </v-card>
          <v-divider class="mt-2" v-if="!itemInStock"></v-divider>
          <v-row align="center" v-if="!itemInStock">
            <v-col class="font-weight-medium">Return Value</v-col>
            <v-col>
              <v-text-field
                v-model="returnPrice"
                outlined
                ref="returnPriceRef"
                class="al-r"
                hide-details
                @focus="$event.target.select()"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            :disabled="itemInStock ? true : false"
            @click="submit()"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import purchaseService from "../service";

export default {
  data() {
    return {
      dialog: false,
      returnPrice: 0,
      returnImeiNumber: null,
      imeiReturnResult: null,
      itemInStock: false,
      isLoading: false,
    };
  },
  methods: {
    close() {
      this.returnPrice = 0;
      this.returnImeiNumber = null;
      this.imeiReturnResult = null;
      this.isLoading = false;
      this.dialog = false;
    },
    submit() {
      this.imeiReturnResult.returnPrice = this.returnPrice;
      this.imeiReturnResult.name = this.imeiReturnResult.Product.name;
      // console.log('this.imeiReturnResult', this.imeiReturnResult)
      this.$emit("submit", this.imeiReturnResult);
      this.close();
    },
    loadImeiNumber() {
      this.isLoading = false;
      this.imeiReturnResult = null;
      return purchaseService
        .search({ imeiNumber: this.returnImeiNumber })
        .then(async (result) => {
          // console.log('result', result)
          if (!result.data) {
            this.$swal({
              title: "Return Item",
              text: "IMEI Number you entered is not found.",
              type: "error",
            });
          } else {
            this.returnPrice = result.data.cost;
            if (result.data.status === "available") {
              this.itemInStock = false;
            } else {
              this.itemInStock = true;
            }
            result.data.Purchase = (
              await purchaseService.getById(result.data.Log[0].referenceNumber)
            ).data;
            this.imeiReturnResult = result.data;
            this.isLoading = true;
            // console.log('imeiReturnResult', this.imeiReturnResult)
          }
        });
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        setTimeout(() => {
          this.$refs.returnImeiNumber.focus();
        }, 100);
      }
    },
  },
};
</script>

<style scoped></style>
